<template>
  <div>
    <SideBarFilter
      :filter="filter"
      ref="sidebarFilterBranch"
      placeholder="Branch"
      @searchAll="searchAll"
      @clearFilter="clearFilter"
      @clearForm="clearForm"
      :hideStatusFilter="false"
      :hideSearchBar="true"
      handleSideBar
    >
      <template v-slot:filter-option>
        <div class="my-3">
          <InputSelect
            v-model="filter.provineId"
            :options="optionProvince"
            title="Province"
            class="mt-2"
            valueField="id"
            textField="name"
          >
            <template v-slot:option-first>
              <b-form-select-option value="" disabled
                >-- Select Province --</b-form-select-option
              >
              <b-form-select-option :value="null">All</b-form-select-option>
            </template>
          </InputSelect>
        </div>
      </template>
    </SideBarFilter>
  </div>
</template>

<script>
export default {
  name: "FilterBranch",
  props: {
    visible: {
      type: Boolean,
      required: false,
    },
    provineId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      filter: {
        provineId: null,
      },
      optionProvince: [],
    };
  },
  watch: {
    visible(val) {
      const defaultProvince = this.provineId === 0 ? null : this.provineId;
      this.filter.provineId = defaultProvince;
      if (val === true) {
        this.getOptionProvince();
        this.$refs.sidebarFilterBranch.show();
      } else {
        this.$refs.sidebarFilterBranch.hide();
      }
    },
  },
  methods: {
    async getOptionProvince() {
      const respone = await this.$services.master.getOptionProvince();
      this.optionProvince = respone.detail.map((o) => {
        return { id: o.provineId, name: o.name };
      });
    },
    searchAll() {
      this.$emit("searchFilter", this.filter.provineId);
    },
    clearFilter() {
      this.filter.provineId = 0;
      this.$emit("searchFilter", this.filter.provineId);
    },
    clearForm() {
      this.$emit("closeFilter");
    },
  },
};
</script>

<style lang="scss" scoped></style>
